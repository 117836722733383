<template>
  <ion-page>
    <ion-content>
      <PageHeader bg="bg-yellow" title="Edit profile">
        <template slot="button">
          <button @click="closeModal" class="mb-2">
            <ArrowLeftIcon/>
          </button>
        </template>
      </PageHeader>
      <RoundedBigCard class="-mt-8 relative z-10">
        <template slot="content">
          <label>
            <img class="w-32 h-32 rounded-full mx-auto border-4 border-white mb-8 shadow-md" :src="user.picture_url"
                 :alt="user.name">
            <input type="file" accept="image/*" class="hidden" @change="handleUpload($event)">
          </label>
          <form @submit.prevent="saveUser">
            <input type="text" class="w-full rounded-full bg-white px-4 py-4 outline-none shadow-xl" v-model="form.name"
                   required max-length="255">
            <input type="email"
                   class="mt-8 w-full rounded-full bg-white px-4 py-4 outline-none shadow-xl"
                   v-model="form.email" required>
            <button type="submit"
                    class="btn-primary bg-lightPurple w-full text-white text-base disabled:opacity-75 mt-24 hlg:mt-56 mb-10"
                    :disabled="working">
              <span v-if="working">Saving...</span>
              <span v-else>Save</span>
            </button>
          </form>
        </template>
      </RoundedBigCard>
    </ion-content>
  </ion-page>
</template>

<script>
import PageHeader from "@/components/shared/PageHeader";
import RoundedBigCard from "@/components/shared/RoundedBigCard";
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon";

export default {
  name: "EditProfileModal",

  components: {ArrowLeftIcon, RoundedBigCard, PageHeader},

  data() {
    return {
      working: false,
      form: {
        name: '',
        email: '',
      }
    }
  },

  methods: {
    closeModal() {
      return this.$ionic.modalController.dismiss();
    },

    async handleUpload(e) {
      if (e.target.files.length == 0) {
        return
      }

      let uploadForm = new FormData()

      uploadForm.append('picture', e.target.files[0])
      uploadForm.append('_method', 'put')

      let user = await this.$store.dispatch('auth/uploadUserPicture', uploadForm)

      this.user.picture_url = user.picture_url
    },

    async saveUser() {
      this.working = true

      await this.$store.dispatch('auth/updateUser', this.form)

      this.closeModal()
    }
  }
}
</script>
