<template>
  <div class="flex flex-col items-center absolute top-0 left-0 right-0 -mt-6">
    <img class="rounded-full h-20 w-20 mb-3 border-4 border-white shadow-md" :src="user.picture_url" :alt="user.name">
    <div class="text-center">
      <h3 class="text-3xl mb-3">{{ user.name }}</h3>
      <h4 class="mb-3 text-lg">{{ user.email }}</h4>
      <button @click="openModal" class="rounded-full px-3 py-1 bg-lightPurple text-white text-sm">Edit profile</button>
    </div>
  </div>
</template>

<script>
import EditProfileModal from "@/components/shared/modals/EditProfileModal";
export default {
  name: 'UserInfo',

  computed: {
    user() {
      return this.$store.state.auth.user
    }
  },

  methods: {
    openModal() {
      return this.$ionic.modalController
        .create({
          component: EditProfileModal,
          componentProps: {
            parent: this.$root,
            data: {
              user: this.user,
              form: {
                name: this.user.name,
                email: this.user.email,
              }
            }
          },
          swipeToClos: true,
        })
        .then(m => m.present())
    },
  }
}
</script>
